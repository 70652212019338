import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import api from "../../api/api";
function Footer() {
  const [phone, setPhone] = React.useState([]);
  const [email, setEmail] = React.useState([]);
  React.useEffect(() => {
    const getPhone = async () => {
      try {
        const response = await api.get("/phone/");
        setPhone(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getPhone();
  }, []);

  React.useEffect(() => {
    const getEmail = async () => {
      try {
        const response = await api.get("/email/");
        setEmail(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getEmail();
  }, []);

  return (
    <footer className="footer">
      <div className="footer__container container">
        <div className="footer__wrapper">
          <div className="footer__head">
            <div className="footer__head-left">
              <div className="footer__head-logo">ТехноАква</div>
              <ul className="footer__head-list">
                <li className="footer__head-element">Контакты:</li>
                {phone &&
                  phone.map((item) => (
                    <li key={item.id} className="footer__head-element">
                      <Link to={`tel:${item.phone}`}>
                        <span>Телефон: </span>
                        {item.phone}
                      </Link>
                    </li>
                  ))}
                {email &&
                  email.map((item) => (
                    <li key={item.id} className="footer__head-element">
                      <span>Почта: </span>
                      {item.email}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="footer__head-right footer__right">
              <div className="footer__right-title">О компании</div>
              <ul className="footer__right-list">
                <li className="footer__right-element">
                  <Link to="/pooly">БАССЕЙНЫ</Link>
                </li>
                <li className="footer__right-element">
                  <Link to="/waterTreatment">
                    ВОДОПОДГОТОВКА В ФАРМАЦЕВТИКЕ
                  </Link>
                </li>
                <li className="footer__right-element">
                  <Link to="/industrialWaterTreatment">
                    ПРОМЫШЛЕННАЯ ВОДОПОДГОТОВКА
                  </Link>
                </li>
                <li className="footer__right-element">
                  <Link to="/waterForHome">ВОДА ДЛЯ ДОМА</Link>
                </li>
                <li className="footer__right-element">
                  <Link to="/services">УСЛУГИ</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__sub">
            <div className="footer__sub-text">
              2023 © technoaqua.ru - Все права защищены
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
