import React from "react";
import "./WaterTreatment.scss";
import CardPoolDesc from "../../component/Card/CardPoolDecs/CardPoolDesc";
function WaterTreatment() {
  return (
    <main className="main">
      <div className="main__container container">
        <div className="main__head">
          <div className="main__head-title">вОДОПОДГОТОВКА В ФАРМАЦЕВТИКЕ</div>
          <div className="main__head-subtitle">
            Имеем большой опыт по работе с оборудованием, компаний&nbsp;
            <span className="BWT">BWT</span>,&nbsp;
            <span className="STILMAS">STILMAS</span>,&nbsp;
            <span className="CHRIST">CHRIST</span>
          </div>
          <div className="main__head-subtitle">
            Таких установок, как SEPTRON, OSMOTRON, выпарных установок Combitron
            и т.п.
          </div>
        </div>

        <div className="description">
          <div className="description__card">
            <div className="description__card-element">
              <div className="description__card-img">
                <img src="/img/Pool/Osmotron.png" alt="" />
              </div>
              <div className="description__card-body">
                <CardPoolDesc
                  title="OSMOTRON"
                  text="Это инновационная система, которая сочетает все необходимые технологические этапы для производства воды самого высокого уровня: умягчение, обратный осмос и электродеионизацию SEPTRON, а также обеспечивает мониторинг и контроль процесса очистки. В результате получается безопасная и чистая вода очищенная."
                />
              </div>
            </div>
            <div className="description__card-element">
              <div className="description__card-img order">
                <img src="/img/Pool/Septron.png" alt="" />
              </div>
              <div className="description__card-body">
                <CardPoolDesc
                  title="SEPTRON"
                  text="Является высокотехнологичным устройством, которое интегрирует в себя ультрафильтрационный этап, обеспечивая наивысшую микробиологическую безопасность и чистоту воды. Это очень важно для фармацевтической промышленности, где качество и чистота воды имеют решающее значение для качества производимых лекарственных средств."
                />
              </div>
            </div>
            <div className="description__card-element">
              <div className="description__card-img orderMedia">
                <img src="/img/Pool/Combitron.png" alt="" />
              </div>
              <div className="description__card-body ">
                <CardPoolDesc
                  title="Combitron"
                  text="Установка Combitron — это инновационный дистиллятор с расширенной первичной колонной, спроектированный для производства высокоочищенного пара. Этот уникальный подход не только снижает затраты на капитал и экономит площадь, но также обеспечивает эффективное производство."
                />
              </div>
            </div>
          </div>
          <div className="description__safety">
            <div className="description__safety-title">
              Безопастность и обслуживание
            </div>
            <div className="description__safety-wrapper">
              <div className="description__safety-text">
                Мы всегда нацелены на то, чтобы создавать наилучшие решения для
                наших клиентов. Наша команда тесно сотрудничает с каждым
                заказчиком, чтобы разработать оптимальный пакет услуг, который
                удовлетворит их индивидуальные потребности и требования. Мы
                осуществляем внимательный подход к каждому проекту и убеждены,
                что каждый наш заказчик заслуживает наилучшего обслуживания.
              </div>
              <div className="description__safety-text">
                Мы предлагаем комплексное эксплуатационное обслуживание нашим
                клиентам по всей России. Наши инженеры гарантируют безопасную и
                бесперебойную работу систем в течение длительного времени. Мы
                понимаем, как важно обеспечить клиентов только качественной,
                сверхчистой водой, поэтому мы уделяем особое внимание каждому
                аспекту нашей работы, чтобы обеспечить безупречный результат.
              </div>
              <div className="description__safety-text">
                Если у вас есть какие-либо вопросы относительно наших услуг,
                наша команда всегда готова оказать поддержку и помощь в решении
                любых задач.
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default WaterTreatment;
