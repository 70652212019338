import React from "react";
import "./Homepage.scss";
import CardHomepage from "../../component/Card/CardHomepage/CardHomepage";
import Contacts from "../../component/Form/Contacts/Contacts";
function Homepage() {
  const [imgFirst, setImgFirst] = React.useState(
    "/img/Card/Homepage/Card1.svg"
  );
  const [imgSeconde, setImgSeconde] = React.useState(
    "/img/Card/Homepage/Card2.svg"
  );
  const [imgThirst, setImgThird] = React.useState(
    "/img/Card/Homepage/Card3.svg"
  );

  const [titleFirst, setTitleFirst] = React.useState("Технологии");
  const [titleSecinde, setTitleSeconde] = React.useState(
    "Инжиниринг и производство"
  );
  const [titleThird, setTitleThird] = React.useState("Качество");

  const [subtitleFirst, setSubtitleFirst] = React.useState(
    "Мы используем последние разработки и исследования во всех аспектах очистки воды"
  );
  const [subtitleSeconde, setSubtitleSeconde] = React.useState(
    "Прокетно-технологический отдел сотрудничает с ведущими производствами стран Европы и Азии, чтобы подобрать для Вас оптимальные решения"
  );
  const [subtitlThird, setSubtitlThird] = React.useState(
    "Водоподготовка, отвечающая Европейским и Российским стандартам качества"
  );
  return (
    <>
      <main className="main">
        <div className="main__container container">
          <div className="main__head">
            <div className="main__head-title">Компания ТехноАква</div>
            <div className="main__head-subtitle">
              Мы обеспечиваем комплекс услуг по водоподготовке.
            </div>
          </div>

          <div className="main__card card">
            <CardHomepage
              img={imgFirst}
              title={titleFirst}
              subtitle={subtitleFirst}
            />
            <CardHomepage
              img={imgSeconde}
              title={titleSecinde}
              subtitle={subtitleSeconde}
            />
            <CardHomepage
              img={imgThirst}
              title={titleThird}
              subtitle={subtitlThird}
            />
          </div>

          <div className="main__action">
            <div className="main__action-title">
              Если есть вопросы, можете нам написать
            </div>
            <div className="main__action-form">
              <Contacts />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Homepage;
