import React from 'react'
import "./CardPoolDesc.scss"
function CardPoolDesc({title, text, orderMedia}) {
  return (
    <div className={`description__card-wrapper ${orderMedia}`}>
      <div className="description__card-title">{title}</div>
      <div className="description__card-text">{text}</div>
    </div>
  );
}

export default CardPoolDesc