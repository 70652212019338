import React from 'react'
import "./Services.scss"
function Services() {
  return (
    <main className="main">
      <div className="main__container container">
        <div className="main__head">
          <div className="main__head-title">Услуги</div>
        </div>
        <div className="main__body">
          <div className="main__body-list">
            <ul className="main__body-row row">
              <li className="row__element">1. Подбор оборудования</li>
              <li className="row__element">
                Наши специалисты подберут вам оборудования исходя из анализа
                воды и ваших пожеланий
              </li>
            </ul>
          </div>
          <div className="main__body-list">
            <ul className="main__body-row row">
              <li className="row__element">2. Монтаж</li>
              <li className="row__element">
                Мы предоставляем услуги по монтажу оборудования и гидравлических
                трубопроводов из различных материалов, таких как нержавеющая
                сталь, PVC, PP, PE и другие. Наша команда специалистов обладает
                высокой квалификацией и большим опытом в данной области, что
                позволяет нам гарантировать высокое качество выполненных работ.
              </li>
            </ul>
          </div>
          <div className="main__body-list">
            <ul className="main__body-row row">
              <li className="row__element">3. Сервисное обслуживание</li>
              <li className="row__element">
                Мы предоставляем квалифицированное обслуживание системы
                водоподготовки нашими опытными специалистами. Наши эксперты
                также предоставляют обучение и поддержку в решении возникающих
                проблем дистанционно в случае необходимости.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Services