import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import Homepage from "./pages/Homepage/Homepage";
import Pool from "./pages/Pool/Pool";
import WaterTreatment from "./pages/WaterTreatment/WaterTreatment";
import IndustrialWaterTreatment from "./pages/IndustrialWaterTreatment/IndustrialWaterTreatment";
import WaterForHome from "./pages/WaterForHome/WaterForHome";
import Services from "./pages/Services/Services";

function App() {
  return (
    <div className="wrapper">
      <Header />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="pooly" element={<Pool />} />
        <Route path="waterTreatment" element={<WaterTreatment />} />
        <Route path="industrialWaterTreatment" element={<IndustrialWaterTreatment/>} />
        <Route path="waterForHome" element={<WaterForHome/>} />
        <Route path="services" element={<Services/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
