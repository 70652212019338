import React from "react";
import "./Nav.scss";
import { Link } from "react-router-dom";
function Nav() {
  const [active, setActive] = React.useState(false);
  const myElementRef = React.useRef(null);

  const handleOnClick = () => {
    setActive(!active);
    document.body.classList.toggle("lock");
  };

  const handleRemoveActive = () => {
    setActive(false);
    document.body.classList.remove("lock");
  };

  React.useEffect(() => {
    function handleScroll() {
      const myElement = myElementRef.current;

      if (window.scrollY > 73) {
        myElement.classList.add("no-fixed");
      } else {
        myElement.classList.remove("no-fixed");
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="header__navigation">
      <div className="header__navigation-container container">
        <div
          className={active ? "header__menu-icon _active" : "header__menu-icon"}
          onClick={() => handleOnClick()}
        >
          <span></span>
        </div>
        <nav
          className={
            active
              ? "header__navigation-menu menu _active"
              : "header__navigation-menu menu"
          }
          ref={myElementRef}
        >
          <ul className="menu__list">
            <li className="menu__list-elemnt">
              <Link
                to="/pooly"
                className="menu__list-link"
                onClick={() => handleRemoveActive()}
              >
                Бассейны
              </Link>
            </li>
            <li className="menu__list-elemnt">
              <Link
                to="/waterTreatment"
                className="menu__list-link"
                onClick={() => handleRemoveActive()}
              >
                ВОДОПОДГОТОВКА В ФАРМАЦЕВТИКЕ
              </Link>
            </li>
            <li className="menu__list-elemnt">
              <Link
                to="/industrialWaterTreatment"
                className="menu__list-link"
                onClick={() => handleRemoveActive()}
              >
                Промышленная водоподготовка
              </Link>
            </li>
            <li className="menu__list-elemnt">
              <Link
                to="/waterForHome"
                className="menu__list-link"
                onClick={() => handleRemoveActive()}
              >
                Вода для дома
              </Link>
            </li>
            <li className="menu__list-elemnt">
              <Link
                to="/services"
                className="menu__list-link"
                onClick={() => handleRemoveActive()}
              >
                Услуги
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Nav;
