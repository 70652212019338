import axios from "axios";


const api = axios.create({
  baseURL: "https://api.technoaqua.ru",
  withCredentials: true,
});


export default api;
