import React from 'react'
import "./CardHomepage.scss"
function CardHomepage({ img, title, subtitle }) {
  return (
    <div className="card__element ">
      <div className="card__element-img">
        <img src={img} alt="" />
      </div>
      <div className="card__element-title">{title}</div>
      <div className="card__element-subtitle">{subtitle}</div>
    </div>
  );
}

export default CardHomepage