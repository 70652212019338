import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import Nav from "../Nav/Nav";
import api from "../../api/api";

function Header() {
  const [phone, setPhone] = React.useState([]);

  React.useEffect(() => {
    const getPhone = async () => {
      try {
        const response = await api.get("/phone/");
        setPhone(response.data);
      } catch(error) {
        console.log(error);
      }
    };

    getPhone();
  }, []);
  return (
    <>
      <header className="header">
        <div className="header__container container">
          <div className="header__logo">
            <Link className="header__link" to="/">
              <img src="/img/Header/logo.png" alt="" />
            </Link>
          </div>
          <div className="header__phone">
            <div className="header__phone-body">
              <img src="/img/Header/phone.svg" alt="" />
              {phone.length > 0 && (
                <Link to={`tel:${phone[0].phone}`}>{phone[0].phone}</Link>
              )}
            </div>
          </div>
        </div>
      </header>
      <Nav />
    </>
  );
}

export default Header;
