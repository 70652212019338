import React from "react";
import CardDescriptionRight from "../../component/Card/CardDescription/CardDescriptionRight/CardDescriptionRight";
import CardDescriptionLeft from "../../component/Card/CardDescription/CardDescriptionLeft/CardDescriptionLeft";
function IndustrialWaterTreatment() {
  const [imgLeft, setImgLeft] = React.useState(
    "/img/Card/Desc/Left/filter.png"
  );
  const [imgRight, setImgRight] = React.useState(
    "/img/Card/Desc/Right/osmos.png"
  );
  const [textLeft, setTextPool] = React.useState([
    {
      id: "1",
      text: "Водоподготовка в промышленности – это важный процесс, который включает в себя использование определенных технологий и оборудования для достижения высокого качества питьевой воды.",
    },
    {
      id: "2",
      text: "Она может включать в себя использование фильтров, обработку ультрафиолетовым светом, обезжелезивание и другие методы, которые позволяют обеспечить безопасность и качество питьевой воды.",
    },
    {
      id: "3",
      text: "В зависимости от конкретных потребностей здания, могут применяться различные методы водоподготовки для достижения желаемого результата.",
    },
  ]);

  const [textRight, setTextOzon] = React.useState([
    {
      id: "1",
      text: "Использование промышленного фильтра с обратным осмосом позволяет обеспечить глубокую деминерализацию воды в больших масштабах. Поток исходной воды под давлением проходит через высокоселективную пористую мембрану или несколько мембран, на которых задерживается до 97-99% всех возможных загрязнителей. Очищенная вода, получаемая в результате, не содержит примесей, таких как ионы тяжелых металлов, натрий, калий, сульфаты, хлориды, бор, фтор и другие неорганические ионы. Пористая структура мембраны позволяет пропускать преимущественно только молекулы воды.",
    },
    {
      id: "2",
      text: "Соотношение между очищенной водой (пермеатом) и водой, сбрасываемой в дренаж (концентратом), составляет около 2:1. То есть, для получения 1000 литров/час очищенной воды, необходимо подавать на установку примерно 1500 литров/час исходной воды. При этом около 500 литров/час концентрата удаляются в канализацию.",
    },
    {
      id: "3",
      text: "Установка для обратного осмоса на производстве позволяет получать высококачественную воду с минимальными затратами. Однако, для правильного выбора и монтажа промышленного оборудования, а также его последующего обслуживания, требуются квалифицированные специалисты с опытом работы в данной области.",
    },
  ]);
  return (
    <main className="main">
      <div className="main__container container">
        <div className="main__head">
          <div className="main__head-title">
            Комплекс услуг по промышленной водоподготовке
          </div>
        </div>
        <div className="main__body">
          <CardDescriptionLeft img={imgLeft} text={textLeft} />
          <CardDescriptionRight img={imgRight} text={textRight}/>
        </div>
      </div>
    </main>
  );
}

export default IndustrialWaterTreatment;
