import React from 'react'
import CardDescriptionRight from "../../component/Card/CardDescription/CardDescriptionRight/CardDescriptionRight";
import CardDescriptionLeft from "../../component/Card/CardDescription/CardDescriptionLeft/CardDescriptionLeft";
function WaterForHome() {
	  const [imgLeft, setImgLeft] = React.useState(
      "/img/Card/Desc/Left/waterLeft.png"
    );
    const [imgRight, setImgRight] = React.useState(
      "/img/Card/Desc/Right/waterRight.png"
    );
    const [textLeft, setTextPool] = React.useState([
      {
        id: "1",
        text: "Очистка воды является важным аспектом в жизни любого человека, независимо от места проживания - это может быть как квартира в многоэтажном доме, так и загородный дом. ",
      },
      {
        id: "2",
        text: "Водопроводная вода, поступающая в наши дома, часто не отвечает стандартам качества, содержа в себе вредные примеси, такие как хлор, нитраты и другие химические соединения.",
      },
      {
        id: "3",
        text: "Вода из колодца или скважины, может содержать в себе микроорганизмы, бактерии и другие загрязнители.",
      },
      {
        id: "4",
        text: "Компания ТехноАква предлагает своим клиентам решения на основе индивидуальных потребностей - надежные и эффективные системы водоподготовки, которые смогут обеспечить идеальное качество воды для дома.",
      },
    ]);

    const [textRight, setTextOzon] = React.useState([
      {
        id: "1",
        text: "Система фильтрации воды — это решение для тех, кто ценит свое здоровье и комфорт в повседневной жизни.",
      },
      {
        id: "2",
        text: "Мы предлагаем индивидуальные решения для каждого клиента, учитывая особенности его дома и потребности в очищенной воде.",
      },
      {
        id: "3",
        text: "Мы гарантируем эффективную многоступенчатую очистку, которая убережет вашу воду от механических примесей, умягчит ее и увеличит срок службы бытовых приборов.",
      },
      {
        id: "4",
        text: "Наша система также сохранит чистоту и блеск сантехники в первозданном виде. При выборе нашей системы вы получаете надежность, практичность, высокое качество и современный дизайн.",
      },
    ]);
  return (
    <main className="main">
      <div className="main__container container">
        <div className="main__head">
          <div className="main__head-title">Вода для дома</div>
        </div>
        <div className="main__body">
          <CardDescriptionLeft img={imgLeft} text={textLeft} />
          <CardDescriptionRight img={imgRight} text={textRight} />
        </div>
      </div>
    </main>
  );
}

export default WaterForHome