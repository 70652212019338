import React from "react";
import "./Pool.scss";
import CardDescriptionRight from "../../component/Card/CardDescription/CardDescriptionRight/CardDescriptionRight";
import CardDescriptionLeft from "../../component/Card/CardDescription/CardDescriptionLeft/CardDescriptionLeft";
import CustomSwiper from "../../component/Swiper/CustomSwiper";
function Pool() {
  const [title, setTitle] = React.useState("Озонирование");
  const [imgLeft, setImgLeft] = React.useState("/img/Card/Desc/Left/pool.png");
  const [imgRight, setImgRight] = React.useState(
    "/img/Card/Desc/Right/Bewason.png"
  );
  const [textLeft, setTextPool] = React.useState([
    {
      id: "1",
      text: "Наша компания заботится о том, чтобы оборудование для бассейнов и аквапарков было в отличном состоянии и всегда работало безупречно. Мы предлагаем регулярное обслуживание, которое включает в себя проверку и чистку всех систем, замену неисправных деталей и настройку оборудования для максимальной производительности.",
    },
    {
      id: "2",
      text: "Мы также предлагаем экстренное обслуживание, если возникают какие-либо проблемы с вашим оборудованием. Наши высококвалифицированные специалисты быстро и эффективно устранят любые неполадки и обеспечат продолжительную работу вашего оборудования.",
    },
    {
      id: "3",
      text: "Мы стремимся к тому, чтобы наши клиенты получали только самое лучшее обслуживание, и готовы работать с вами, чтобы удовлетворить все ваши потребности. Свяжитесь с нами сегодня, чтобы узнать больше о том, как мы можем помочь вам поддерживать ваше оборудование для бассейнов и аквапарков в отличном состоянии.",
    },
  ]);

  const [textRight, setTextOzon] = React.useState([
    {
      id: "1",
      text: "Существует множество методов очистки воды в бассейнах, но одним из самых эффективных является озонирование. Оно позволяет не только очистить воду от бактерий, вирусов и других паразитов, но и предотвратить образование налета на трубах и в самом бассейне.",
    },
    {
      id: "2",
      text: "Преимущества озонирования воды бассейна очевидны. Оно не раздражает кожу и глаза, не влияет на волосы и не меняет кислотность среды. Более того, оно безопасно для здоровья, так как оставшийся озон со временем превращается в обычный кислород. Кроме того, скорость очистки воды при использовании озонатора гораздо выше, чем при использовании обычных химических средств, таких как хлор.",
    },
    {
      id: "3",
      text: "Таким образом, если вы хотите наслаждаться чистой и безопасной водой в своем бассейне, рекомендуется использовать озонатор. Он компактен, прост в использовании и не наносит вреда окружающей среде.",
    },
  ]);
  return (
    <main className="main">
      <div className="main__container container">
        <div className="page__slider slider">
          <div className="slider__body">
            <CustomSwiper />
          </div>
        </div>
        <div className="main__head">
          <div className="main__head-title">
            обслуживание БАССЕЙНОВ и АКВАПАРКОВ
          </div>
        </div>
        <div className="main__body">
          <CardDescriptionLeft img={imgLeft} text={textLeft} />
          <CardDescriptionRight title={title} img={imgRight} text={textRight} />
        </div>
      </div>
    </main>
  );
}

export default Pool;
