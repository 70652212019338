import React from "react";

function CardDescriptionRight({ title, img, text }) {
  return (
    <div className="main__body-element mt102px">
      <div className="main__body-right right">
        <img src={img} alt="" />
      </div>
      <div className="main__body-left left">
        {title && <div className="left__title">{title}</div>}
        {text &&
          text.map((item) => (
            <div key={item.id} className="left__text">
              {item.text}
            </div>
          ))}
      </div>
    </div>
  );
}

export default CardDescriptionRight;
