import React from 'react'
import "./CardDescription.scss"
function CardDescriptionLeft({ img, text }) {
  return (
    <div className="main__body-element">
      <div className="main__body-left left">
        {text &&
          text.map((item) => (
            <div key={item.id} className="left__text">
              {item.text}
            </div>
          ))}
      </div>

      <div className="main__body-right right">
        <img src={img} alt="" />
      </div>
    </div>
  );
}

export default CardDescriptionLeft;